module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/srv/www/depression.knowmentalhealth.com/src/layouts/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GeneSight","short_name":"GeneSight","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb008acc38fa2d3458751122116f85cb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-50598578-4","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0},
    }]
