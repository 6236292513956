import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Trans, withI18n } from '@lingui/react';

// styles
import '../styles/style.scss';

class TemplateWrapper extends Component {
  constructor(props) {
    super(props);
    this._$refTemplateWrapper = null;
    this.state = {
      $refTemplateWrapper: null,
      decoratorCls: '',
      funcDecorator: (cls) => {
        this.setState({
          decoratorCls: cls,
        });
      },
    };
  }
  
  componentDidMount() {
    this.setState({
      $refTemplateWrapper: this._$refTemplateWrapper,
    });
  }

  render() {
    const { pathName, children, location } = this.props;
    const {
      $refTemplateWrapper, funcDecorator, decoratorCls,
    } = this.state;

    return (
      <div
        ref={(_mount) => {
          this._$refTemplateWrapper = _mount;
        }}
        className={`main-wrapper ${decoratorCls}`}
        >
        <TemplateWrapperContext.Provider
          value={{ $refTemplateWrapper, funcDecorator }}
        >
          <Helmet defaultTitle="GeneSight" titleTemplate="%s - How Depression Feels">
            <meta name="og:type" content="website" />
            <meta name="og:site_name" content="GeneSight" />
            <title>GeneSight - How Depression Feels?</title>
            <meta name="description" content="The everyday challenges experienced by real people who suffer from depression" />
          </Helmet>
          <main className="main-content">
            { children }
          </main>
        </TemplateWrapperContext.Provider>
      </div>
    );
  }
}

export default withI18n()(TemplateWrapper);
export const TemplateWrapperContext = React.createContext();
