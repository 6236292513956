import React, { Component } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import TemplateWrapper from '../components/TemplateWrapper';
import {
  catalogs, langFromPath, languages, prefix, deprefix,
} from '../i18n-config';
import Transition from '../components/Transition';

i18n.load('en', catalogs.en.messages);
i18n.load('fr', catalogs.fr.messages);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: langFromPath(props.location.pathname),
    };
    i18n.activate(langFromPath(props.location.pathname));
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const lang = langFromPath(this.props.location.pathname);
      i18n.activate(lang);
    }
  }

  render() {
    const pathName = this.props.location.pathname;
    const lang = langFromPath(pathName);

    return (
      <I18nProvider i18n={i18n}>
        <TemplateWrapper key={this.state.lang} location={this.props.location} languages={languages} pathName={pathName} lang={lang}>
          <Transition location={this.props.location}>{this.props.children}</Transition>
        </TemplateWrapper>
      </I18nProvider>
    );
  }
}

export default Layout;
